import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthModule, authRoutes, TokenInterceptor, TokenSessionStorage } from '@eternalife/auth';
import { ToasterService } from 'angular2-toaster';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbTokenStorage } from '@nebular/auth';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    children: authRoutes,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation: 'ignore',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, config),
    AuthModule,
  ],
  providers: [
    ToasterService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi : true },
    { provide: NbTokenStorage, useClass: TokenSessionStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
