import { Route } from '@angular/router';
import { NgxAuthComponent } from './containers/auth/auth.component';
import { AzureLoginComponent } from './containers/azure/azure-login.component';
import { AzureCallbackComponent } from './containers/azure/azure-callback.component';
import { NgxLogoutComponent } from './containers/logout/logout.component';
import { NgxLoginComponent } from "./containers/login/login.component";
import { LineCallbackComponent } from './containers/line/line-callback.component';

export const authRoutes: Route[] = [
  {
    path: '',
    component: NgxAuthComponent,
    children: [{
      path: 'login',
      component: NgxLoginComponent,
    }, {
      path: 'logout',
      component: NgxLogoutComponent,
    }, {
      path: 'line-callback',
      component: LineCallbackComponent,
    }, {
      path: 'azure-login',
      component: AzureLoginComponent,
    }, {
      path: 'azure-callback',
      component: AzureCallbackComponent,
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login' }
   ],
  }
];
