// https://akveo.github.io/nebular/docs/auth/nbtokenlocalstorage#nbtokenlocalstorage

import { Injectable } from '@angular/core';
import { NbAuthToken, NbAuthTokenParceler, NbTokenStorage } from '@nebular/auth';

@Injectable()
export class TokenSessionStorage extends NbTokenStorage {

  protected key = 'auth_app_token';

  constructor(private parceler: NbAuthTokenParceler) {
    super();
  }

  /**
   * Returns token from sessionStorage
   * @returns {NbAuthToken}
   */
  get(): NbAuthToken {
    const raw = sessionStorage.getItem(this.key) as string;
    return this.parceler.unwrap(raw);
  }

  /**
   * Sets token to sessionStorage
   * @param {NbAuthToken} token
   */
  set(token: NbAuthToken) {
    const raw = this.parceler.wrap(token);
    sessionStorage.setItem(this.key, raw);
  }

  /**
   * Clears token from sessionStorage
   */
  clear(): any {
    sessionStorage.removeItem(this.key);
  }

}

