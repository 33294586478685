import { Injectable } from '@angular/core';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
  NbOAuth2AuthStrategyOptions,
  NbAuthStrategyClass,
  NbAuthResult,
  NbAuthIllegalTokenError,
  NbAuthOAuth2Token
} from '@nebular/auth';


// Create new token for Azure auth so it returns id_token instead of access_token
export class LineAuthToken extends NbAuthOAuth2Token {

  // let's rename it to exclude name clashes
  static override NAME = 'nb:auth:line:token';

  constructor(accessToken: string, idToken: string, refreshToken: string) {
    super({
      'access_token': accessToken,
      'idToken': idToken,
      'refresh_token': refreshToken
    }, 'line-token');
  }
}

@Injectable()
export class LineAuthStrategy extends NbOAuth2AuthStrategy {

  // we need this method for strategy setup
  static override setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [LineAuthStrategy, options];
  }

  protected override redirectResultHandlers = {
    [NbOAuth2ResponseType.CODE]: () => {
      return of(this.route.snapshot.queryParams).pipe(
        switchMap((params: any) => {
          if (params.code) {
            return this.requestToken(params.code);
          }

          return of(
            new NbAuthResult(
              false,
              params,
              this.getOption('redirect.failure'),
              this.getOption('defaultErrors'),
              [],
            ));
        }),
      );
    },
    id_token: () => {
      const module = 'authorize';
      const requireValidToken = this.getOption(`${module}.requireValidToken`);
      return of(this.route.snapshot.fragment)
        .pipe(
          // filter((fragment) => fragment != null),
          // map((fragment: string) => this.parseHashAsQueryParams(fragment)),
          map((params: any) => {
            if (!params.error) {
              return new NbAuthResult(
                true,
                params,
                this.getOption('redirect.success'),
                [],
                this.getOption('defaultMessages'),
                this.createToken(params, requireValidToken));
            }
            return new NbAuthResult(
              false,
              params,
              this.getOption('redirect.failure'),
              this.getOption('defaultErrors'),
              [],
            );
          }),
          catchError(err => {
            const errors = [];
            if (err instanceof NbAuthIllegalTokenError) {
              errors.push(err.message);
            } else {
              errors.push('Something went wrong.');
            }
            return of(
              new NbAuthResult(
                false,
                err,
                this.getOption('redirect.failure'),
                errors,
              ));
          }),
      );
    },
  };


  protected override redirectResults: any = {
    [NbOAuth2ResponseType.CODE]: () => of(null),

    id_token: () => {
      return of(this.route.snapshot.fragment)
            .pipe(
              // tap(value => console.log('value:', value),
              // filter((fragment) => fragment),
              // map((fragment: string) => this.parseHashAsQueryParams(fragment)),
              // map((params: any) => !!(params && (params.id_token || params.error))),
            )
    }
  };
}
