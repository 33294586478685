<ngx-auth-block>
  <h2 class="title">
    <a [routerLink]="['/pages/home']">
      <img src="./assets/auth_logo-min.png" width="250"/>
    </a>
    <br/>
  </h2>

  <h4>登出中，請稍後...</h4>

</ngx-auth-block>
