import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class LineAuthService {


  constructor(private http: HttpClient) {

  }

  login(client_id: string, redirect_uri: string) {
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=12345abcde&scope=profile%20openid&nonce=09876xyz`
    window.location.href = url;
  }
}
