<ngx-auth-block>
  <h2 class="title">
    <a [routerLink]="['/pages/home']">
      <img src="./assets/auth_logo-min.png" width="250" />
    </a>
    <br />
  </h2>

  <button class="btn btn-block btn-outline-primary" [class.btn-pulse]="submitted" (click)="login()">
    登入
  </button>

</ngx-auth-block>
