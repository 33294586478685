import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent
} from '@angular/common/http';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: NbAuthService) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (request.url.includes('https://maps.googleapis.com')) {
      return next.handle(request);
    }
    this.authService.getToken().subscribe(token => {
      if (token.getValue() == null) {
        return;
      }
      const payload = JSON.parse(token.getValue());
      request = this.addToken(request, payload["access_token"]);
    });
    return next.handle(request);
  }
}
