import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxAuthComponent } from './containers/auth/auth.component';
import { NgxLoginComponent } from './containers/login/login.component';
import { NgxAuthBlockComponent } from './containers/auth-block/auth-block.component';
import { AzureLoginComponent } from './containers/azure/azure-login.component';
import { AzureCallbackComponent } from './containers/azure/azure-callback.component';
import { NgxLogoutComponent } from './containers/logout/logout.component';
import { NbAuthModule, NbAuthService, NbTokenService, NbTokenStorage } from '@nebular/auth';
import { TokenSessionStorage } from './containers/token-storage/token-session-storage';
import { LineAuthService } from './services/line-auth.service';
import { LineCallbackComponent } from './containers/line/line-callback.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NbAuthModule.forRoot()
  ],
  declarations: [
    NgxAuthComponent,
    NgxAuthBlockComponent,
    NgxLoginComponent,
    NgxLogoutComponent,
    AzureLoginComponent,
    AzureCallbackComponent,
    LineCallbackComponent
  ],
  providers: [
    NbAuthService,
    NbTokenService,
    LineAuthService,
    // CookieService,
    { provide: NbTokenStorage, useClass: TokenSessionStorage },
  ]
})
export class AuthModule {}
